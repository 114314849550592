<template>
  <div class="wrapper">
    <div class="card" @click="qyz">
      <div class="title">平安银行税金贷（企业主版）</div>
      <div class="detail">
        <p>1. 申请人为企业的前两大股东（持股≥20%）</p>
        <p>2. 纯线上申请</p>
        <p>3. 最高额度50万元</p>
      </div>
      <van-button type="primary" block round>立即申请</van-button>
    </div>
    <div class="card" @click="fr">
      <div class="title">平安银行税金贷（法人版）</div>
      <div class="detail">
        <p>1. 申请人为法定代表人或者企业的第一大自然人股东</p>
        <p style="color: #ff9102; font-weight: bold">2. 需追加配偶或另一位主申请人</p>
        <p>3. 最高额度100万元</p>
        <p style="color: #ff9102; font-weight: bold">4. 需线下复审</p>
      </div>
      <van-button type="primary" block round>立即申请</van-button>
    </div>
  </div>
</template>

<script>
import {Button} from 'vant'
export default {
  name: "PinganPage",
  components: {
    [Button.name]: Button
  },
  methods: {
    qyz() {
      location.href = 'https://f.orangebank.com.cn/nscf/H5/index.html#/A01_infor3?inputChId=sjtgylcdfh&custMaId=WUWANLI568&channelUser=01&spread=w&from=click'
    },
    fr() {
      location.href = 'https://f.orangebank.com.cn/nscf/H5/index.html#/C01_CorIn_infor1?inputChId=sjtgylcdfh&custMaId=WUWANLI568&channelUser=01&spread=w&from=click'
    }
  }
}
</script>

<style scoped>
.wrapper {
  font-size: 32px;
  padding: 15px;
}
.card {
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  padding: 30px;
  margin-top: 30px;
}
.title {
  border-bottom: 1px solid #e3e3e3;
  line-height: 56px;
}
.detail {
  margin: 30px 0;
  font-size: 26px;
  line-height: 36px;
}
</style>